import { axiosReq } from '@/shared/api';

const NEXT_PUBLIC_ORIGIN = process.env.NEXT_PUBLIC_ORIGIN || '';

export function logoutCurrentUser() {
  return axiosReq({
    url: `${NEXT_PUBLIC_ORIGIN}/api/auth/logout/`,
    method: 'DELETE',
  });
}
