import { axiosReq } from '@/shared/api';
import {
  GetInvitationsTransformedResponse,
  PaginationProps,
} from '@/shared/types';

const NEXT_PUBLIC_ORIGIN = process.env.NEXT_PUBLIC_ORIGIN || '';

type GetInvitationsProps = {
  include?: {
    project?: boolean;
    company?: boolean;
    user?: boolean;
    inviter?: boolean;
  };
  token?: string;
  signal?: AbortSignal;
  filter?: {
    company?: string;
    project?: string;
    pending?: boolean;
  };
};
export async function getInvitations(
  { include, filter, token, signal }: GetInvitationsProps,
  pagination: PaginationProps
) {
  let url = `${NEXT_PUBLIC_ORIGIN}/api/invitations/?page[size]=${pagination.size}&page[number]=${pagination.number}`;

  if (include) {
    url = url.concat(`&include=`);
    const includeArray = [];
    if (include.project) {
      includeArray.push(`project`);
    }

    if (include.company) {
      includeArray.push(`company`);
    }

    if (include.user) {
      includeArray.push(`user`);
    }

    if (include.inviter) {
      includeArray.push(`inviter`);
    }

    url = url.concat(includeArray.join(','));
  }

  if (!!filter?.company?.length) {
    url = url.concat(`&filter[company]=${filter.company}`);
  }
  if (!!filter?.project?.length) {
    url = url.concat(`&filter[project]=${filter.project}`);
  }
  if (filter?.pending) {
    url = url.concat('&filter[is_approved]=false');
  }

  return axiosReq<GetInvitationsTransformedResponse>({
    method: 'GET',
    url,
    signal,
    token,
  });
}
