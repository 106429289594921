import { axiosReq } from '@/shared/api';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

type RemoveUserFromProjectProps = {
  userId: string;
  projectId: string;
};
export async function removeUserFromProject({
  userId,
  projectId,
}: RemoveUserFromProjectProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/users/${userId}/remove_from_project/${projectId}/`;

  return axiosReq({
    url,
    method: 'DELETE',
  });
}
