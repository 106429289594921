import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export function dateFormat(
  date: number | null,
  format = 'DD.MM.YYYY',
  isInSeconds: boolean = true
) {
  return dayjs(isInSeconds ? Number(`${date}000`) : date).format(format);
}
