import { FC, JSXElementConstructor, ReactNode } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { Alert, Box, Flex, Loader } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import { useLoadMore } from '@/shared/hooks/useLoadMore';
import { PaginationProps } from '@/shared/types';

type InfinityScrollProps = {
  resetTrigger: string;
  request: (pagination: PaginationProps) => Promise<any>;
  pagination: PaginationProps;
  onPaginationChange: (pagination: PaginationProps) => void;
  listRenderer: FC<{ items: any[] }>;
  initialItems?: any[];
  sentryComponent?: JSXElementConstructor<any>;
  sentryProps?: Record<string, any>;
  alertComponentWrapper?: JSXElementConstructor<{ children: ReactNode }>;
  loadingComponent?: ReactNode;
  emptyListComponent?: JSXElementConstructor<any>;
};
export const InfinityScroll = ({
  resetTrigger,
  request,
  pagination,
  onPaginationChange,
  listRenderer: ListRenderer,
  initialItems = [],
  sentryComponent: SentryComponent = Box,
  alertComponentWrapper: AlertWrapper = ({ children }) => <>{children}</>,
  loadingComponent,
  emptyListComponent: EmptyListComponent,
  sentryProps = {},
}: InfinityScrollProps) => {
  const { isLoading, error, items, hasNextPage, loadMore } = useLoadMore({
    initialItems,
    request,
    pagination,
    resetTrigger,
    onPaginationChange,
  });

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 200px 0px',
  });

  if (error) {
    return (
      <AlertWrapper>
        <Alert
          color="red"
          icon={<IconInfoCircle />}
          mb={26}
          title={`${error.title} ${error.code}`}
          variant="outline"
        >
          {error.detail}
        </Alert>
      </AlertWrapper>
    );
  }

  if (EmptyListComponent && !items.length && !hasNextPage && !isLoading) {
    return <EmptyListComponent />;
  }

  return (
    <>
      {<ListRenderer items={items} />}

      <SentryComponent {...sentryProps} ref={sentryRef} />

      {(isLoading || hasNextPage) &&
        (loadingComponent || (
          <Flex justify="center">
            <Loader size="xl" type="dots" />
          </Flex>
        ))}
    </>
  );
};
