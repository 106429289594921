import { Skeleton, Table, TableTbody, TableTd, TableTr } from '@mantine/core';

const rowsCount = 3;

export const UserTableLoader = () => (
  <TableTr display="contents">
    <TableTd colSpan={7} display="contents" p={0}>
      <Table display="contents" verticalSpacing="md">
        <TableTbody display="contents">
          {Array.from({ length: rowsCount }).map((_, index) => (
            <TableTr data-testid="user-table-loader-row" key={index}>
              <TableTd w="15%">
                <Skeleton data-testid="user-table-loader-skeleton" h={20} />
              </TableTd>

              <TableTd w="17%">
                <Skeleton data-testid="user-table-loader-skeleton" h={20} />
              </TableTd>

              <TableTd w="20%">
                <Skeleton data-testid="user-table-loader-skeleton" h={20} />
              </TableTd>

              <TableTd w="30%">
                <Skeleton data-testid="user-table-loader-skeleton" h={20} />
              </TableTd>

              <TableTd w="10%">
                <Skeleton data-testid="user-table-loader-skeleton" h={20} />
              </TableTd>

              <TableTd w="10%">
                <Skeleton data-testid="user-table-loader-skeleton" h={20} />
              </TableTd>
            </TableTr>
          ))}
        </TableTbody>
      </Table>
    </TableTd>
  </TableTr>
);
