import { PropsWithChildren, useMemo } from 'react';

import { RoleOfList } from '@/shared/types';

import { AllRolesContext } from './allRolesContext';

type AllRolesProviderProps = PropsWithChildren<{
  roles: RoleOfList[] | null;
}>;
export const AllRolesProvider = ({
  roles,
  children,
}: AllRolesProviderProps) => {
  const value = useMemo(() => (roles ? { roles } : { roles: [] }), [roles]);

  return (
    <AllRolesContext.Provider value={value}>
      {children}
    </AllRolesContext.Provider>
  );
};
