import { useMemo } from 'react';

import { Avatar, Group, TableTd, TableTr, Text } from '@mantine/core';

import {
  DeleteUserFromProjectButton,
  ResendInvitationButton,
} from '@/features/user/client';

import { useProjectStore, useUsersTabStore } from '@/entities/project/store';

import { dateFormat } from '@/shared/lib';
import { UserOfList } from '@/shared/types';

import { UserRoleInProject } from './UserRoleInProject';

type ProjectPageUsersTabTableRowProps = {
  user: UserOfList;
};
export const ProjectPageUsersTabTableRow = ({
  user,
}: ProjectPageUsersTabTableRowProps) => {
  const { projectsUsers } = useUsersTabStore('projectsUsers');
  const { projectId } = useProjectStore('projectId');

  const addToProjectDate = useMemo(() => {
    return projectsUsers.find(
      (item) =>
        item.attributes.project_id === projectId &&
        item.attributes.user_id === user.id
    )?.attributes.created_at;
  }, [projectsUsers, projectId, user.id]);

  return (
    <TableTr data-testid="users-tab-table-row">
      <TableTd>
        <Group gap={10}>
          <Avatar
            data-testid="users-tab-table-row-avatar"
            size={28}
            src={user.attributes.avatar_link}
          />

          <Text
            data-testid="users-tab-table-row-name"
            fw={600}
            size="sm"
            variant="mono"
          >
            {user.attributes.full_name || 'Unknown user'}
          </Text>
        </Group>
      </TableTd>

      <TableTd>
        <Text
          data-testid="users-tab-table-row-name"
          fw={600}
          size="sm"
          variant="mono"
        >
          {user.attributes.email}
        </Text>
      </TableTd>

      <TableTd>
        <Text
          data-testid="users-tab-table-row-role"
          fw={600}
          size="sm"
          variant="mono"
        >
          <UserRoleInProject roles={user.attributes.projects_roles} />
        </Text>
      </TableTd>

      <TableTd>
        <Text
          data-testid="users-tab-table-row-adding-date"
          fw={600}
          size="sm"
          variant="mono"
        >
          {addToProjectDate ? dateFormat(addToProjectDate) : '-'}
        </Text>
      </TableTd>

      <TableTd>
        {user.attributes.last_login_at && (
          <Text
            data-testid="users-tab-table-row-last-visit"
            fw={600}
            size="sm"
            variant="mono"
          >
            {dateFormat(user.attributes.last_login_at)}
          </Text>
        )}
      </TableTd>

      <TableTd ta="right">
        <ResendInvitationButton user={user} />
      </TableTd>

      <TableTd ta="right">
        <DeleteUserFromProjectButton user={user} />
      </TableTd>
    </TableTr>
  );
};
