import { useState } from 'react';

import { ActionIcon, Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { IconRefresh } from '@tabler/icons-react';

import { useProjectStore } from '@/entities/project/store';
import { resendInviteToProject } from '@/entities/user/api';

import { UserOfList } from '@/shared/types';

type ResendInvitationButtonProps = {
  user: UserOfList;
};
export const ResendInvitationButton = ({
  user,
}: ResendInvitationButtonProps) => {
  const { projectId } = useProjectStore('projectId');
  const [isLoading, setIsLoading] = useState(false);
  const canResend = !user.attributes.last_login_at;

  const handleResendInvitation = () => {
    openConfirmModal({
      title: 'Please confirm your action',
      children: (
        <Text size="sm">
          {`Are you sure you want to resend invitation to the project to ${user.attributes.email}?`}
        </Text>
      ),
      labels: { confirm: 'Send', cancel: 'Cancel' },
      onConfirm: () => {
        setIsLoading(true);

        resendInviteToProject({
          userId: user.id,
          projectId,
        })
          .then((resp) => {
            if ('error' in resp) {
              notifications.show({
                title: 'Failed to resend invitation',
                message: `Failed to resend invitation to ${user.attributes.email}`,
                color: 'red',
              });
              return;
            }

            notifications.show({
              title: 'Invitation resent',
              message: `Invitation has been resent to ${user.attributes.email}`,
              color: 'green',
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
    });
  };

  if (!canResend) {
    return null;
  }

  return (
    <ActionIcon
      aria-label="Resend invitation"
      color="gray"
      loading={isLoading}
      radius="xl"
      variant="transparent"
      onClick={handleResendInvitation}
    >
      <IconRefresh />
    </ActionIcon>
  );
};
