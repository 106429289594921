import { axiosReq } from '@/shared/api';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

type CreateCompanyResponse = {
  data: {
    id: string;
    type: 'companies';
    attributes: {
      title: string;
      created_at: string;
      updated_at: string;
    };
    relationships: {
      data: {
        type: string;
        id: string;
      };
    };
  };
  included?: [
    {
      id: string;
      type: string;
      attributes: {
        full_name: string;
        email: string;
        is_superuser: true;
        is_approved: true;
        created_at: string;
        updated_at: string;
      };
    }
  ];
};
type CreateCompanyProps = {
  title: string;
  signal?: AbortSignal;
  token?: string;
};
export async function createCompany({
  title,
  signal,
  token,
}: CreateCompanyProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/companies/`;

  return axiosReq<CreateCompanyResponse>({
    url,
    method: 'POST',
    token,
    signal,
    data: {
      data: {
        type: 'companies',
        attributes: {
          title,
        },
      },
    },
  });
}
