import { MouseEventHandler, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Loader,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuTarget,
  Text,
} from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';
import { IconChevronDown, IconPlus } from '@tabler/icons-react';

import { captureException } from '@sentry/core';

import { getCompanies } from '@/entities/companies/api';
import { useCompaniesStore } from '@/entities/companies/client';
import { useCurrentUserStore } from '@/entities/user/client';

import { CompanyOfList } from '@/shared/types';
import { canCreateOrRemoveCompany, isSuperUser } from '@/shared/userRoles';
import { getOwnCompanies } from '@/shared/utils/getOwnCompanies';

import styles from './CompaniesGlobalSelector.module.css';

type CompaniesGlobalSelectorProps = {
  isOwnOnly?: boolean;
};
export const CompaniesGlobalSelector = ({
  isOwnOnly: shouldShowOwnCompanies = false,
}: CompaniesGlobalSelectorProps) => {
  const {
    companiesList,
    setCompaniesList,
    listHash,
    selectedCompany,
    setSelectedCompany,
  } = useCompaniesStore(
    'listHash',
    'companiesList',
    'setCompaniesList',
    'selectedCompany',
    'setSelectedCompany'
  );
  const { user: currentUser } = useCurrentUserStore('user');
  const isOwnOnly = useMemo(
    () => shouldShowOwnCompanies && !isSuperUser(currentUser),
    [shouldShowOwnCompanies, currentUser]
  );

  const [isLoading, setIsLoading] = useState(false);

  const canCreateCompany = useMemo(() => {
    return canCreateOrRemoveCompany(currentUser);
  }, [currentUser]);

  useDidUpdate(() => {
    setIsLoading(true);

    const abortController = new AbortController();

    getCompanies({ signal: abortController.signal }, { number: 1, size: 999 })
      .then((resp) => {
        if ('errors' in resp) {
          return;
        }

        if (isOwnOnly) {
          const ownCompanies = getOwnCompanies(
            resp.data,
            currentUser.attributes.companies_roles
          );

          setCompaniesList(ownCompanies);

          return;
        }

        setCompaniesList(resp.data);
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      abortController.abort();
    };
  }, [listHash]);

  const filteredCompanies = useMemo(() => {
    if (isOwnOnly) {
      return getOwnCompanies(
        companiesList,
        currentUser.attributes.companies_roles
      );
    }

    return companiesList;
  }, [companiesList, currentUser.attributes.companies_roles, isOwnOnly]);

  useEffect(() => {
    const companiesIds = filteredCompanies.map((company) => company.id);

    if (
      selectedCompany &&
      isOwnOnly &&
      !companiesIds.includes(selectedCompany)
    ) {
      setSelectedCompany('');
    }
  }, [filteredCompanies, isOwnOnly, selectedCompany, setSelectedCompany]);

  const companiesMap = useMemo<Record<string, CompanyOfList>>(() => {
    return companiesList.reduce(
      (acc, company) => ({
        ...acc,
        [company.id]: company,
      }),
      {}
    );
  }, [companiesList]);

  const handleSelectCompany: MouseEventHandler<HTMLButtonElement> = async (
    event
  ) => {
    const nextValue = event.currentTarget.dataset.value || '';

    if (nextValue === selectedCompany) {
      return;
    }

    setIsLoading(true);
    fetch('/api/settings/switch-company/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ companyId: nextValue }),
    })
      .catch((error) => {
        console.error(error);
        captureException(error);
      })
      .finally(() => {
        setIsLoading(false);
        setSelectedCompany(nextValue);
      });
  };
  const handleAddCompany = () => {
    openContextModal({
      modal: 'addCompany',
      title: 'Add company',
      innerProps: {},
    });
  };

  if (isLoading) {
    return (
      <Box ml={20}>
        <Loader size="sm" type="dots" />
      </Box>
    );
  }

  return (
    <Menu
      closeOnItemClick
      withArrow
      arrowPosition="center"
      classNames={{ item: styles.menuItem }}
      closeDelay={400}
      data-testid="select-company-dropdown"
      offset={8}
      openDelay={100}
      position="bottom-start"
      shadow="md"
      trigger="hover"
      width={200}
    >
      <MenuTarget>
        <Button rightSection={<IconChevronDown />} variant="transparent">
          <Text mt="1px" size="xs" variant="mono">
            {companiesMap[selectedCompany]?.attributes.title ?? 'All companies'}
          </Text>
        </Button>
      </MenuTarget>

      <MenuDropdown className={styles.menuDropdown}>
        {canCreateCompany && (
          <MenuItem component="div" p={0} onClick={handleAddCompany}>
            <Button
              fullWidth
              data-testid="add-company-btn"
              fz={14}
              justify="space-between"
              px={12}
              rightSection={<IconPlus />}
              size="md"
              variant="filled"
            >
              Add Company
            </Button>
          </MenuItem>
        )}

        <MenuItem
          data-testid="all-companies"
          data-value={''}
          onClick={handleSelectCompany}
        >
          All companies
        </MenuItem>

        {filteredCompanies.map((company) => (
          <MenuItem
            data-testid="companies-menu-item"
            data-value={company.id}
            key={company.id}
            classNames={{
              itemLabel: styles.itemLabel,
            }}
            onClick={handleSelectCompany}
          >
            {company.attributes.title}
          </MenuItem>
        ))}
      </MenuDropdown>
    </Menu>
  );
};
