import { PropsWithChildren, useRef } from 'react';

import {
  createUserManagementStore,
  UserManagementContext,
  UserManagementProps,
  UserManagementStore,
} from './userManagementStoreContext';

export const UserManagementStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<UserManagementProps>>) => {
  const storeRef = useRef<UserManagementStore>();

  if (!storeRef.current) {
    storeRef.current = createUserManagementStore(props);
  }

  return (
    <UserManagementContext.Provider value={storeRef.current}>
      {children}
    </UserManagementContext.Provider>
  );
};
