import { axiosReq } from '@/shared/api';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

type DeleteUserResponse = null;
type DeleteUserProps = {
  id: string;
};
export async function deleteUser({ id }: DeleteUserProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/users/${id}/disable/`;

  return axiosReq<DeleteUserResponse>({
    url,
    method: 'DELETE',
  });
}
