import { PropsWithChildren, useRef } from 'react';

import {
  createInvitationsStore,
  InvitationsContext,
  InvitationsProps,
  InvitationsStore,
} from './invitationsStoreContext';

export const InvitationsStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<InvitationsProps>>) => {
  const storeRef = useRef<InvitationsStore>();

  if (!storeRef.current) {
    storeRef.current = createInvitationsStore(props);
  }

  return (
    <InvitationsContext.Provider value={storeRef.current}>
      {children}
    </InvitationsContext.Provider>
  );
};
