import { TableTh, TableThead, TableTr } from '@mantine/core';

import { useProjectStore } from '@/entities/project/store';

import { SortableTableHeaderItem } from '@/shared/ui/client';

export const ProjectPageUsersTableHead = () => {
  const { userOrderBy, setUserOrderBy } = useProjectStore(
    'userOrderBy',
    'setUserOrderBy'
  );
  const handleSort = (sortField: string) => {
    debugger;
    setUserOrderBy(userOrderBy === sortField ? `-${sortField}` : sortField);
  };

  return (
    <TableThead>
      <TableTr>
        <TableTh pt={0}>
          <SortableTableHeaderItem
            dataTestId="users-tab-header-name"
            label="User name"
            value="full_name"
            onClick={handleSort}
          />
        </TableTh>

        <TableTh data-testId="users-tab-header-email" pt={0}>
          Email
        </TableTh>

        <TableTh data-testid="users-tab-header-role" pt={0} w={120}>
          Role
        </TableTh>

        <TableTh data-testid="users-tab-header-adding-date" pt={0} w={120}>
          Addition date
        </TableTh>

        <TableTh data-testid="users-tab-header-last-visit" pt={0} w={120}>
          Last visit
        </TableTh>

        <TableTh pt={0} w={48} />

        <TableTh pt={0} w={48} />
      </TableTr>
    </TableThead>
  );
};
