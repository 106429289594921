import { makeCommonStoreHook } from '@/shared/hooks/useCommonStore';

import {
  UserManagementContext,
  UserManagementState,
} from './userManagementStoreContext';

export const useUserManagementStore = makeCommonStoreHook<UserManagementState>(
  UserManagementContext
);
