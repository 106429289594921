import { PropsWithChildren, useRef } from 'react';

import {
  CompaniesProps,
  CompaniesStore,
  CompaniesStoreContext,
  createCompaniesStore,
} from './companiesStoreContext';

export const CompaniesStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<CompaniesProps>>) => {
  const storeRef = useRef<CompaniesStore>();

  if (!storeRef.current) {
    storeRef.current = createCompaniesStore(props);
  }

  return (
    <CompaniesStoreContext.Provider value={storeRef.current}>
      {children}
    </CompaniesStoreContext.Provider>
  );
};
