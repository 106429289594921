import { Component, PropsWithChildren } from 'react';

import { captureException } from '@sentry/core';

import { ErrorPage } from '@/app/system/ErrorPage';

export class ErrorBoundary extends Component<PropsWithChildren> {
  state = { hasError: false };

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: unknown) {
    console.error(error);
    console.error(errorInfo);
    captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
