import { useMemo } from 'react';

import { Alert, Box, Table, TableTbody } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import { useProjectStore } from '@/entities/project/store';

import { canViewInvitesInProject } from '@/shared/userRoles';

import { useCurrentUserStore } from '../../client';
import { ProjectPageUsersTabInfinityScroll } from '../ProjectPageUsersTabInfinityScroll';
import { ProjectPageUsersTableHead } from '../ProjectPageUsersTableHead';

type ProjectPageUsersTabProps = {};
export const ProjectPageUsersTab = ({}: ProjectPageUsersTabProps) => {
  const { user } = useCurrentUserStore('user');
  const { projectId, companyId } = useProjectStore('projectId', 'companyId');

  const isVisible = useMemo(() => {
    return canViewInvitesInProject(user, projectId, companyId);
  }, [companyId, projectId, user]);

  return (
    <Box data-testid="project-page-user-tab">
      {/* <PendingUserJoinRequestsToProject /> */}

      {!isVisible && (
        <Alert
          color="yellow"
          data-testid="users-tab-error"
          icon={<IconInfoCircle />}
          maw={450}
          mb={20}
          title="Your current role in the project only allows you to grant read-only access to project data."
          variant="light"
        >
          You can only invite a person as a guest. Guest can not perform actions
          with the project, data sources and connections but he will be able to
          see the data from project source connections in project dashboards. If
          you need to add a person with different set of permissions, please
          contact the project owner.
        </Alert>
      )}

      <Table
        highlightOnHover
        c="dark.9"
        data-testid="users-tab-table"
        layout="fixed"
        verticalSpacing="md"
        withRowBorders={false}
      >
        <ProjectPageUsersTableHead />

        <TableTbody>
          <ProjectPageUsersTabInfinityScroll />
        </TableTbody>
      </Table>
    </Box>
  );
};
