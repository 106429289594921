import { PropsWithChildren, useRef } from 'react';

import {
  createDashboardsStore,
  DashboardsStore,
  DashboardsStoreContext,
  DashboardsStoreProps,
} from '.';

export const DashboardsStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<DashboardsStoreProps>>) => {
  const storeRef = useRef<DashboardsStore>();

  if (!storeRef.current) {
    storeRef.current = createDashboardsStore(props);
  }

  return (
    <DashboardsStoreContext.Provider value={storeRef.current}>
      {children}
    </DashboardsStoreContext.Provider>
  );
};
