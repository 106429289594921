import { createContext } from 'react';

import { randomId } from '@mantine/hooks';

import { createStore } from 'zustand';

export type ProjectStoreProps = {
  projectId: string;
  companyId: string;
  projectCode: string;
  projectName: string;
  userOrderBy: string;
  usersListHash: string;
};

export type ProjectStoreState = ProjectStoreProps & {
  setProjectId: (projectId: string) => void;
  setCompanyId: (companyId: string) => void;
  setProjectCode: (projectCode: string) => void;
  setProjectName: (projectName: string) => void;
  setUserOrderBy: (userOrderBy: string) => void;
  updateUsersListHash: () => void;
};
export type ProjectStore = ReturnType<typeof createProjectStore>;

export const createProjectStore = (initProps?: Partial<ProjectStoreProps>) => {
  const DEFAULT_PROPS: Partial<ProjectStoreProps> = {
    projectId: '',
    companyId: '',
    projectCode: '',
    projectName: '',
    userOrderBy: 'full_name',
    usersListHash: randomId(),
  };
  const initialValues = {
    ...DEFAULT_PROPS,
    ...initProps,
  } as ProjectStoreProps;

  return createStore<ProjectStoreState>()((set) => ({
    ...initialValues,
    setProjectId: (projectId) => set({ projectId }),
    setCompanyId: (companyId) => set({ companyId }),
    setProjectCode: (projectCode) => set({ projectCode }),
    setProjectName: (projectName) => set({ projectName }),
    setUserOrderBy: (userOrderBy) => set({ userOrderBy }),
    updateUsersListHash: () => set({ usersListHash: randomId() }),
  }));
};

export const ProjectStoreContext = createContext<ProjectStore | null>(null);
export const ProjectStoreContextProvider = ProjectStoreContext.Provider;
