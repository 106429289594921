'use client';

import { useMemo } from 'react';

import { ActionIcon, Tooltip } from '@mantine/core';
import { openContextModal } from '@mantine/modals';
import { IconTrash } from '@tabler/icons-react';

import { useProjectStore } from '@/entities/project/store';
import { useCurrentUserStore } from '@/entities/user/client';

import { UserOfList } from '@/shared/types';
import { canAddAndRemoveAUserInProject } from '@/shared/userRoles';

type DeleteUserFromProjectButtonProps = {
  user: UserOfList;
};
export const DeleteUserFromProjectButton = ({
  user,
}: DeleteUserFromProjectButtonProps) => {
  const { projectId, projectName, companyId, updateUsersListHash } =
    useProjectStore(
      'projectId',
      'projectName',
      'companyId',
      'updateUsersListHash'
    );

  const { user: currentUser } = useCurrentUserStore('user');

  const canBeDeleted = useMemo(() => {
    return canAddAndRemoveAUserInProject(currentUser, projectId, companyId);
  }, [companyId, currentUser, projectId]);

  const handleOpenDeleteUserModal = () => {
    openContextModal({
      size: 'sm',
      modal: 'deleteUserFromProject',
      title: `Deleting ${user.attributes.full_name} (${user.attributes.email}) from project ${projectName}`,
      innerProps: {
        user,
        projectId,
        projectName,
        companyId,
        updateHash: updateUsersListHash,
      },
    });
  };

  if (!canBeDeleted) {
    return (
      <Tooltip
        multiline
        withArrow
        label="This user can't be removed from the project or you have no permissions to remove them"
        w={220}
      >
        <ActionIcon
          disabled
          bg="transparent"
          color="black"
          data-testid="delete-from-project-btn"
          radius="xl"
          variant="transparent"
          onClick={handleOpenDeleteUserModal}
        >
          <IconTrash />
        </ActionIcon>
      </Tooltip>
    );
  }

  return (
    <ActionIcon
      color="gray"
      radius="xl"
      variant="transparent"
      onClick={handleOpenDeleteUserModal}
    >
      <IconTrash />
    </ActionIcon>
  );
};
