import { axiosReq, transformGetCurrentUserResponse } from '@/shared/api';
import { GetCurrentUserResponse } from '@/shared/types';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

type GetCurrentUserProfileProps = {
  signal?: AbortSignal;
  token?: string;
  include?: {
    companies?: boolean;
  };
};
export async function getCurrentUserProfile({
  signal,
  token,
  include,
}: GetCurrentUserProfileProps) {
  let url = `${NEXT_PUBLIC_BACKEND_URL}v1/profile/?include=companies,projects,source_sets,source_sets.source`;

  if (include) {
    const includeArray = [];

    if (include.companies) {
      includeArray.push(`companies_users`);
    }

    url = url.concat(includeArray.join(','));
  }

  const resp = await axiosReq<GetCurrentUserResponse>({
    url,
    method: 'GET',
    signal,
    token,
  });

  if ('error' in resp) {
    return resp;
  } else if ('data' in resp) {
    return transformGetCurrentUserResponse(resp);
  }
}
