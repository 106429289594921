'use client';

import { FC, ReactNode, useCallback, useState } from 'react';

import { Alert, TableTd, TableTr } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';

import { useCompaniesStore } from '@/entities/companies/client';
import { useProjectStore, useUsersTabStore } from '@/entities/project/store';
import { getUsers } from '@/entities/user/api';

import { PaginationProps, ResponseError, UserOfList } from '@/shared/types';
import { InfinityScroll } from '@/shared/ui/InfinityScroll';

import { ProjectPageUsersTabTableRow } from '../ProjectPageUsersTabTableRow';
import { UserTableLoader } from '../UserTableLoader';

const TableRowForWrappers = ({ children }: { children: ReactNode }) => {
  return (
    <TableTr>
      <TableTd colSpan={6}>{children}</TableTd>
    </TableTr>
  );
};
const ListRenderer: FC<{ items: UserOfList[] }> = ({ items }) => {
  return items.map((user) => (
    <ProjectPageUsersTabTableRow key={user.id} user={user} />
  ));
};
export const ProjectPageUsersTabInfinityScroll = () => {
  const { projectId, userOrderBy, usersListHash } = useProjectStore(
    'projectId',
    'userOrderBy',
    'usersListHash'
  );
  const [pagination, setPagination] = useState<PaginationProps>({
    number: 1,
    size: 10,
  });
  const { selectedCompany } = useCompaniesStore('selectedCompany');
  const { appendProjectsUsers } = useUsersTabStore('appendProjectsUsers');
  const resetTrigger = `${usersListHash}-${selectedCompany}-${userOrderBy}`;
  const [error, setError] = useState<ResponseError | null>(null);

  const request = useCallback(
    async (_pagination: PaginationProps) => {
      const resp = await getUsers(
        {
          filter: {
            project: projectId,
          },
          include: { projectsUsers: true },
          orderBy: userOrderBy,
        },
        _pagination
      );

      if ('errors' in resp) {
        setError(resp.errors[0]);
        return;
      }
      if (!('list' in resp)) {
        return [];
      }
      if ('projectsUsers' in resp) {
        appendProjectsUsers(resp.projectsUsers);
      }
      return resp.list;
    },
    [projectId, userOrderBy, appendProjectsUsers]
  );

  if (error) {
    return (
      <TableTr>
        <TableTd colSpan={7}>
          <Alert
            color="red"
            data-testid="sources-container-error"
            icon={<IconInfoCircle />}
            mb={26}
            mt={10}
            title={`${error.title} ${error.code}`}
            variant="outline"
          >
            {error.detail}
          </Alert>
        </TableTd>
      </TableTr>
    );
  }

  return (
    <InfinityScroll
      alertComponentWrapper={TableRowForWrappers}
      initialItems={[]}
      listRenderer={ListRenderer}
      loadingComponent={<UserTableLoader />}
      pagination={pagination}
      request={request}
      resetTrigger={resetTrigger}
      sentryComponent={TableTr}
      onPaginationChange={setPagination}
    />
  );
};
