import { axiosReq } from '@/shared/api';
import { GetSignDocumentsResponse } from '@/shared/types';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

type GetSignDocumentsProps = {
  signal?: AbortSignal;
  token?: string;
};
export async function getSignDocuments({
  signal,
  token,
}: GetSignDocumentsProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/documents_versions/to_sign`;

  return axiosReq<GetSignDocumentsResponse>({
    url,
    method: 'GET',
    token,
    signal,
  });
}
