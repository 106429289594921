import { Context, useContext } from 'react';

import { StoreApi, useStore } from 'zustand';

type StoreContext<T> = Context<StoreApi<T> | null>;
type StoreProps<T> = Array<keyof T>;

const useCommonStore = <T>(
  context: StoreContext<T>,
  ...props: StoreProps<T>
) => {
  const store = useContext(context);

  if (!store) throw new Error('Missing Context.Provider in the tree');

  return useStore(store, (state) => {
    const result: Partial<T> = {};

    return props.reduce((acc, prop) => {
      return {
        ...acc,
        [prop]: state[prop],
      };
    }, result) as Pick<T, (typeof props)[number]>;
  });
};

/**
 * Made to reuse the same logic for all stores;
 * It accepts a context and returns a hook wrapper that accepts any number of arguments;
 * Said arguments must be keys of the store's state;
 * The hook returns the state of the store, but only for the keys that were passed as arguments;
 * Inspired by the "storeon" library;
 * @link https://github.com/storeon/storeon
 */
export const makeCommonStoreHook = <T>(context: StoreContext<T>) => {
  return (...props: StoreProps<T>) => useCommonStore<T>(context, ...props);
};
