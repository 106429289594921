import { axiosReq } from '@/shared/api';
import { GetPotentialDeletedSourcesTransformedResponse } from '@/shared/types';

type GetPotentionalDeletedSourcesProps = {
  id: string;
  removalType: 'company' | 'project';
  removalId: string;
  signal?: AbortSignal;
  token?: string;
};

const NEXT_PUBLIC_ORIGIN = process.env.NEXT_PUBLIC_ORIGIN;

export async function GetPotentionalDeletedSources({
  id,
  removalType,
  removalId,
  signal,
  token,
}: GetPotentionalDeletedSourcesProps) {
  const url = `${NEXT_PUBLIC_ORIGIN}/api/potential_deleted_sources/?removal_type=${removalType}&removal_id=${removalId}&user_id=${id}`;

  return axiosReq<GetPotentialDeletedSourcesTransformedResponse>({
    url,
    method: 'GET',
    token,
    signal,
  });
}
