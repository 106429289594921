import { PropsWithChildren, useRef } from 'react';

import {
  createProjectStore,
  ProjectStore,
  ProjectStoreContextProvider,
  ProjectStoreProps,
} from './projectStoreContext';

export const ProjectStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<ProjectStoreProps>>) => {
  const storeRef = useRef<ProjectStore>();

  if (!storeRef.current) {
    storeRef.current = createProjectStore(props);
  }

  return (
    <ProjectStoreContextProvider value={storeRef.current}>
      {children}
    </ProjectStoreContextProvider>
  );
};
