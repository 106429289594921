import { redirect } from 'next/navigation';

import axios from 'axios';

import { ResponseError } from '@/shared/types';

const SESSION_COOKIE_NAME = process.env.NEXT_PUBLIC_SESSION_COOKIE_NAME || '';
const NEXT_PUBLIC_ORIGIN = process.env.NEXT_PUBLIC_ORIGIN;
const isDev = process.env.NODE_ENV === 'development';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (isDev) {
      console.error(error.response?.data?.errors ?? error.response?.data);
    }

    const isServer = typeof window === 'undefined';
    const isNotAuth =
      error.response?.status === 401 ||
      error.response?.data?.errors?.[0]?.code === 401;

    if (isNotAuth && !isServer) {
      window.location.href = `${NEXT_PUBLIC_ORIGIN}/login/?error=unauthorized`;
    } else if (isNotAuth) {
      return redirect(`${NEXT_PUBLIC_ORIGIN}/login/?error=unauthorized`);
    }

    return Promise.reject(error);
  }
);

type AxiosReqProps = {
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
  token?: string;
  url: string;
  data?: Record<string, any>;
  headers?: Record<string, string | undefined>;
  cookies?: Record<string, string | undefined>;
  signal?: AbortSignal;
};
export function axiosReq<T = {}>({
  method,
  url,
  data,
  headers = {},
  cookies = {},
  token,
  signal,
}: AxiosReqProps): Promise<T | { errors: ResponseError[] }> {
  const headersFull = {
    ...headers,
  };

  if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
    headersFull['Content-Type'] = 'application/vnd.api+json';
  }

  let cookiesFull = {
    ...cookies,
  };

  if (token) {
    cookiesFull[SESSION_COOKIE_NAME] = token;
  }

  if (Object.keys(cookiesFull).length > 0) {
    headersFull.Cookie = Object.entries(cookiesFull)
      .map(([key, value]) => `${key}=${value};path=/`)
      .join('; ');
  }

  return axios
    .request<T>({
      method,
      url,
      data,
      headers: headersFull,
      withCredentials: true,
      signal,
    })
    .then((response) => {
      if ('data' in response && typeof response.data === 'object') {
        return response.data;
      }

      return {};
    })
    .catch((error) => {
      if (error.code === 'ERR_CANCELED') {
        return {
          errors: [
            {
              code: 499,
              title: 'Request was canceled',
              detail: 'Canceled by client',
            },
          ],
        };
      }

      if (error.response?.data?.errors) {
        return error.response.data;
      }

      return {
        errors: [
          {
            code: 500,
            title: 'Something went wrong',
            detail: 'This error has no details',
          },
        ],
      };
    });
}
