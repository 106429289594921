import { createContext } from 'react';

import { randomId } from '@mantine/hooks';

import { createStore } from 'zustand';

import { DEFAULT_PAGINATION } from '@/shared/constants';
import {
  CompanyOfList,
  InvitationOfList,
  PaginationProps,
  ProjectOfList,
  UserOfList,
} from '@/shared/types';

export type InvitationsProps = {
  invitationsList: InvitationOfList[];
  projects: Record<string, ProjectOfList>;
  companies: Record<string, CompanyOfList>;
  users: Record<string, UserOfList>;
  total: number;
  listHash: string;
  pagination: PaginationProps;
};
export type InvitationsState = InvitationsProps & {
  updateListHash: () => void;
  setInvitationsList: (invitationsList: InvitationOfList[]) => void;
  appendInvitationsList: (invitationsList: InvitationOfList[]) => void;
  appendProjects: (projects: Record<string, ProjectOfList>) => void;
  appendCompanies: (companies: Record<string, CompanyOfList>) => void;
  appendUsers: (users: Record<string, UserOfList>) => void;
  setTotal: (total: number) => void;
  setPagination: (pagination: PaginationProps) => void;
  reset: () => void;
};

export type InvitationsStore = ReturnType<typeof createInvitationsStore>;

export const createInvitationsStore = (
  initProps: Partial<InvitationsProps>
) => {
  const getDefaultProps = () =>
    ({
      listHash: randomId(),
      invitationsList: [],
      projects: {},
      companies: {},
      users: {},
      total: 0,
      pagination: DEFAULT_PAGINATION,
      invitations: [],
    } as InvitationsProps);
  const initialValues = {
    ...getDefaultProps(),
    ...initProps,
  } as InvitationsProps;

  return createStore<InvitationsState>()((set, get) => ({
    ...initialValues,
    updateListHash: () =>
      set({ listHash: randomId(), pagination: DEFAULT_PAGINATION }),
    appendInvitationsList: (invitationsList) =>
      set({ invitationsList: [...get().invitationsList, ...invitationsList] }),
    reset: () =>
      set({
        ...getDefaultProps(),
      }),
    setInvitationsList: (invitationsList) => set({ invitationsList }),
    appendProjects: (projects) =>
      set({
        projects: {
          ...get().projects,
          ...projects,
        },
      }),
    appendCompanies: (companies) =>
      set({
        companies: {
          ...get().companies,
          ...companies,
        },
      }),
    appendUsers: (users) =>
      set({
        users: {
          ...get().users,
          ...users,
        },
      }),
    setTotal: (total) => set({ total }),
    setPagination: (pagination) => set({ pagination }),
  }));
};

export const InvitationsContext = createContext<InvitationsStore | null>(null);
