import { axiosReq } from '@/shared/api';
import { GetUsersTransformedResponse, PaginationProps } from '@/shared/types';

const NEXT_PUBLIC_ORIGIN = process.env.NEXT_PUBLIC_ORIGIN || '';

type GetUsersProps = {
  signal?: AbortSignal;
  token?: string;
  orderBy?: string;
  include?: {
    projects?: boolean;
    projectsFiltered?: boolean;
    companies?: boolean;
    companiesFiltered?: boolean;
    companiesUsers?: boolean;
    projectsUsers?: boolean;
  };
  filter?:
    | {
        company: string;
      }
    | {
        project: string;
      };
};
export async function getUsers(
  { signal, token, orderBy, include, filter }: GetUsersProps,
  pagination: PaginationProps
) {
  let url = `${NEXT_PUBLIC_ORIGIN}/api/users/?page[size]=${pagination.size}&page[number]=${pagination.number}`;

  if (include) {
    url = url.concat(`&include=`);

    const includeArray = [];

    if (include.projects) {
      includeArray.push(`working_projects`);
    }

    if (include.companies) {
      includeArray.push(`companies`);
    }

    if (include.projectsFiltered) {
      includeArray.push(`working_projects_filtered`);
    }

    if (include.companiesFiltered) {
      includeArray.push(`companies_filtered`);
    }

    if (include.companiesUsers) {
      includeArray.push(`companies_users`);
    }

    if (include.projectsUsers) {
      includeArray.push(`projects_users`);
    }

    url = url.concat(includeArray.join(','));
  }

  if (filter) {
    if ('company' in filter && filter.company) {
      url = url.concat(`&filter[company]=${filter.company}`);
    }
    if ('project' in filter && filter.project) {
      url = url.concat(`&filter[project]=${filter.project}`);
    }
  }

  if (orderBy) {
    url = url.concat(`&sort=${orderBy}`);
  }

  return axiosReq<GetUsersTransformedResponse>({
    url,
    method: 'GET',
    token,
    signal,
  });
}
