import { DOMAttributes, ReactNode } from 'react';

import { Button, ButtonProps } from '@mantine/core';

import styles from './PillButton.module.css';

type PillButtonProps = DOMAttributes<HTMLButtonElement> &
  ButtonProps & {
    children: ReactNode;
  };
export const PillButton = ({ children, ...props }: PillButtonProps) => {
  return (
    <Button
      className={styles.root}
      color="gray"
      radius="xl"
      size="xs"
      variant="outline"
      {...props}
    >
      {children}
    </Button>
  );
};
