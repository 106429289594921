import {
  CompanyOfList,
  DataSourceOfList,
  GetCurrentUserResponse,
  SourceSetOfList,
} from '@/shared/types';

export function transformGetCurrentUserResponse(
  response: GetCurrentUserResponse
) {
  const { data, included } = response;
  let sourceSets: SourceSetOfList[] = [];
  let companies: Record<string, CompanyOfList> = {};
  let sourceSetsSources: DataSourceOfList[] = [];

  if (included) {
    sourceSets = included.filter(
      (item) => item.type === 'source_sets'
    ) as SourceSetOfList[];

    sourceSetsSources = included.filter(
      (item) => item.type === 'sources'
    ) as DataSourceOfList[];

    const respCompanies = included.filter(
      (item) => item.type === 'companies'
    ) as CompanyOfList[];
    respCompanies.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, companies);
  }

  return {
    user: data,
    sourceSets,
    companies,
    sourceSetsSources,
  };
}
