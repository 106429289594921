import { PropsWithChildren, useRef } from 'react';

import {
  createUsersTabStore,
  UsersTabStore,
  UsersTabStoreContext,
  UsersTabStoreProps,
} from './usersTabStoreContext';

export const UsersTabStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<UsersTabStoreProps>>) => {
  const storeRef = useRef<UsersTabStore>();

  if (!storeRef.current) {
    storeRef.current = createUsersTabStore(props);
  }

  return (
    <UsersTabStoreContext.Provider value={storeRef.current}>
      {children}
    </UsersTabStoreContext.Provider>
  );
};
