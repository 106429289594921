import { createContext } from 'react';

import { createStore } from 'zustand';

import { ProjectUser } from '@/shared/types';

export type UsersTabStoreProps = {
  projectsUsers: ProjectUser[];
};

export type UsersTabStoreState = UsersTabStoreProps & {
  appendProjectsUsers: (projectsUsers: ProjectUser[]) => void;
};
export type UsersTabStore = ReturnType<typeof createUsersTabStore>;

export const createUsersTabStore = (
  initProps?: Partial<UsersTabStoreProps>
) => {
  const DEFAULT_PROPS: Partial<UsersTabStoreProps> = {
    projectsUsers: [],
  };
  const initialValues = {
    ...DEFAULT_PROPS,
    ...initProps,
  } as UsersTabStoreProps;

  return createStore<UsersTabStoreState>()((set, get) => ({
    ...initialValues,
    appendProjectsUsers: (projectsUsers) =>
      set({
        projectsUsers: [...get().projectsUsers, ...projectsUsers],
      }),
  }));
};

export const UsersTabStoreContext = createContext<UsersTabStore | null>(null);
