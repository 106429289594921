import { MouseEventHandler, useMemo, useState } from 'react';

import {
  Button,
  Flex,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuTarget,
  Text,
} from '@mantine/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { useQueryState } from 'nuqs';

import { useCompaniesStore } from '@/entities/companies/client';

import { sendEvent } from '@/shared/analytics';

type Option = {
  value: string;
  label: string;
  inverted?: boolean;
};

type SelectOrderByProps = {
  onChange: (value: string) => void;
  value: string;
  options: Option[];
};
export const SelectOrderBy = ({
  onChange,
  options,
  value,
}: SelectOrderByProps) => {
  const { selectedCompany, companiesList } = useCompaniesStore(
    'selectedCompany',
    'companiesList'
  );
  const [opened, setOpen] = useState(false);

  const [, setSearchParamsOrderBy] = useQueryState('orderBy');

  const optionsAsObject = useMemo(
    () =>
      options.reduce<Record<string, (typeof options)[0]>>((acc, item) => {
        acc[item.value] = item;
        return acc;
      }, {}),
    [options]
  );

  const handleChange: MouseEventHandler<HTMLButtonElement> = (event) => {
    const selected = event.currentTarget.dataset.value || '';

    setSearchParamsOrderBy(selected || '').then((searchParams) =>
      onChange(searchParams.get('orderBy') || '')
    );

    const company = companiesList.find((c) => c.id === selectedCompany);
    sendEvent({
      event_name: 'click_sort',
      company: company?.attributes.title || 'all',
      type: selected.replace('-', '') || 'default',
    });
  };

  return (
    <Menu opened={opened} position="bottom-end" onChange={setOpen}>
      <MenuTarget>
        <Flex align="center">
          <Text size="xs" variant="mono">
            Sort by:
          </Text>

          <Button
            data-testid="select-order-by"
            fz={12}
            rightSection={opened ? <IconChevronUp /> : <IconChevronDown />}
            size="xs"
            tt="uppercase"
            variant="transparent"
          >
            <Text fw={600} size="xs" variant="mono">
              {value !== 'default' ? (value.includes('-') ? '↓' : '↑') : null}

              {optionsAsObject[value]?.label ?? 'Default'}
            </Text>
          </Button>
        </Flex>
      </MenuTarget>

      <MenuDropdown data-testid="select-order-dropdown">
        <MenuItem
          component={'button'}
          data-testid="select-order-by-default"
          data-value={''}
          tt="uppercase"
          onClick={handleChange}
        >
          <Flex c="indigo" fz={12} gap={8}>
            Default
          </Flex>
        </MenuItem>

        {options.map((item) => {
          return (
            <MenuItem
              component={'button'}
              data-value={item.value}
              key={item.value}
              tt="uppercase"
              onClick={handleChange}
            >
              <Flex
                c="indigo"
                data-testid="select-order-by-option"
                fz={12}
                gap={8}
              >
                {item.value.includes('-') ? '↓' : '↑'}

                {item.label}
              </Flex>
            </MenuItem>
          );
        })}
      </MenuDropdown>
    </Menu>
  );
};
