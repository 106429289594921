import { sendGTMEvent } from '@next/third-parties/google';

const disableAnalytics =
  process.env.NEXT_PUBLIC_ANALYTICS_DISASABLED === 'true';

type SendEventProps = {
  [key: string]: any;
  event?: never;
};
export function sendEvent(eventProps: SendEventProps) {
  if (disableAnalytics) {
    return;
  }

  sendGTMEvent({
    ...eventProps,
    event: 'custom_event',
    userId: window.currentUserId,
  });
}
