import { createContext } from 'react';

import { randomId } from '@mantine/hooks';

import { createStore } from 'zustand';

import { DEFAULT_PAGINATION } from '@/shared/constants';
import {
  CompanyOfList,
  CompanyUser,
  PaginationProps,
  ProjectOfList,
  UserOfList,
} from '@/shared/types';

export type UserManagementProps = {
  usersList: UserOfList[];
  projects: Record<string, ProjectOfList>;
  companies: Record<string, CompanyOfList>;
  companiesUsers: CompanyUser[];
  total: number;
  listHash: string;
  pagination: PaginationProps;
  orderBy: string;
};
export type UserManagementState = UserManagementProps & {
  updateListHash: () => void;
  setUsersList: (usersList: UserOfList[]) => void;
  appendList: (usersList: UserOfList[]) => void;
  appendProjects: (projects: Record<string, ProjectOfList>) => void;
  appendCompanies: (companies: Record<string, CompanyOfList>) => void;
  appendCompaniesUsers: (companiesUsers: CompanyUser[]) => void;
  setTotal: (total: number) => void;
  setPagination: (pagination: PaginationProps) => void;
  reset: () => void;
  setOrderBy: (orderBy: string) => void;
};

export type UserManagementStore = ReturnType<typeof createUserManagementStore>;

export const createUserManagementStore = (
  initProps: Partial<UserManagementProps>
) => {
  const getDefaultProps = () =>
    ({
      listHash: randomId(),
      usersList: [],
      projects: {},
      companies: {},
      companiesUsers: [],
      total: 0,
      pagination: DEFAULT_PAGINATION,
      orderBy: '',
    } as UserManagementProps);
  const initialValues = {
    ...getDefaultProps(),
    ...initProps,
  } as UserManagementProps;

  return createStore<UserManagementState>()((set, get) => ({
    ...initialValues,
    updateListHash: () =>
      set({ listHash: randomId(), pagination: DEFAULT_PAGINATION }),
    reset: () =>
      set({
        ...getDefaultProps(),
      }),
    setUsersList: (usersList) => set({ usersList }),
    appendList: (usersList) =>
      set({ usersList: [...get().usersList, ...usersList] }),
    appendProjects: (projects) =>
      set({
        projects: {
          ...get().projects,
          ...projects,
        },
      }),
    appendCompanies: (companies) =>
      set({
        companies: {
          ...get().companies,
          ...companies,
        },
      }),
    appendCompaniesUsers: (companiesUsers) =>
      set({
        companiesUsers: [...get().companiesUsers, ...companiesUsers],
      }),
    setTotal: (total) => set({ total }),
    setPagination: (pagination) => set({ pagination }),
    setOrderBy: (orderBy) => set({ orderBy }),
  }));
};

export const UserManagementContext = createContext<UserManagementStore | null>(
  null
);
