import { createContext } from 'react';

import { createStore } from 'zustand';

import {
  CurrentUser,
  DataSourceOfList,
  SignDocument,
  SignDocumentVersion,
  SourceSetOfList,
} from '@/shared/types';

export type CurrentUserProps = {
  sourceSets: SourceSetOfList[];
  sourceSetsSources: DataSourceOfList[];
  user: CurrentUser;
  documents: SignDocument[];
  documentsVersions: SignDocumentVersion[];
};
export type CurrentUserState = CurrentUserProps & {
  setData: (data: CurrentUserProps) => void;
  setDocumentsVersions: (documentsVersions: SignDocumentVersion[]) => void;
};

export type CurrentUserStore = ReturnType<typeof createCurrentUserStore>;

export const createCurrentUserStore = (
  initProps: Partial<CurrentUserProps>
) => {
  const getDefaultProps = () =>
    ({
      user: {} as CurrentUser,
      sourceSets: [],
      sourceSetsSources: [],
      documents: [],
      documentsVersions: [],
    } as CurrentUserProps);

  const initialValues = {
    ...getDefaultProps(),
    ...initProps,
  } as CurrentUserProps;

  return createStore<CurrentUserState>()((set, get) => ({
    ...initialValues,
    setData: (data) => set({ ...get(), ...data }),
    setDocumentsVersions: (versions) =>
      set({ ...get(), documentsVersions: versions }),
  }));
};

export const CurrentUserContext = createContext<CurrentUserStore | null>(null);
