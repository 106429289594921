import { ReactNode } from 'react';

import { Avatar, Box, Card, Group, Text } from '@mantine/core';

import { UserOfList } from '@/shared/types';

type UserCardProps = {
  data: UserOfList;
  children?: ReactNode;
};
export const UserCard = ({ data, children }: UserCardProps) => {
  return (
    <Card withBorder h="100%" style={{ justifyContent: 'end' }}>
      <Box
        style={{
          position: 'relative',
        }}
      >
        <Group align="flex-start" gap={5} justify="space-between" wrap="nowrap">
          <Box maw={'75%'}>
            <Text component="div" fw="bold" size="sm">
              {data.attributes.full_name || 'Unknown user'}
            </Text>

            <Text
              truncate
              c="dark.0"
              component="div"
              size="xs"
              tt="uppercase"
              variant="mono"
            >
              {data.attributes.email}
            </Text>
          </Box>

          <Avatar radius="xl" size={28} src={data.attributes.avatar_link} />
        </Group>
      </Box>

      {children}
    </Card>
  );
};
