import { axiosReq } from '@/shared/api';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

type RemoveUserFromCompanyProps = {
  userId: string;
  companyId: string;
};
export async function removeUserFromCompany({
  userId,
  companyId,
}: RemoveUserFromCompanyProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/users/${userId}/remove_from_company/${companyId}/`;

  return axiosReq({
    url,
    method: 'DELETE',
  });
}
