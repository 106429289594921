import { axiosReq } from '@/shared/api';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

type ResendInviteToProjectResponse = {};
type ResendInviteToProjectProps = {
  userId: string;
  projectId: string;
  signal?: AbortSignal;
};
export async function resendInviteToProject({
  userId,
  projectId,
  signal,
}: ResendInviteToProjectProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/users/${userId}/resend_invitation/${projectId}/`;

  return axiosReq<ResendInviteToProjectResponse>({
    url,
    method: 'POST',
    signal,
  });
}
