'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { ListItem, Text } from '@mantine/core';

import clsx from 'clsx';

import styles from './SidebarLinkItem.module.css';

type MenuItemProps = {
  path: string;
  title: string;
};
export function SidebarLinkItem({ path, title }: MenuItemProps) {
  const currentPathname = usePathname();

  return (
    <ListItem
      className={clsx({
        [styles.menuItemActive]: currentPathname.includes(path),
      })}
    >
      <Text
        className={styles.menuItemLink}
        component={Link}
        href={path}
        size="sm"
      >
        {title}
      </Text>
    </ListItem>
  );
}
