import { axiosReq } from '@/shared/api';
import { CompanyOfList, PaginationProps } from '@/shared/types';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

export type GetCompaniesResponse = {
  data: CompanyOfList[];
  meta: Record<string, any>;
};
type GetCompaniesProps = {
  include?: {
    projects?: boolean;
  };
  signal?: AbortSignal;
  token?: string;
};
export async function getCompanies(
  { signal, token, include }: GetCompaniesProps,
  pagination: PaginationProps
) {
  let url = `${NEXT_PUBLIC_BACKEND_URL}v1/companies/?page[size]=${pagination.size}&page[number]=${pagination.number}`;

  if (include) {
    url = url.concat(`&include=`);

    const includeArray = [];

    if (include.projects) {
      includeArray.push(`projects`);
    }

    url = url.concat(includeArray.join(','));
  }

  return axiosReq<GetCompaniesResponse>({
    url,
    token,
    method: 'GET',
    signal,
  });
}
