import { useCallback, useReducer } from 'react';

import { useDidUpdate } from '@mantine/hooks';

import { PaginationProps, ResponseError } from '@/shared/types';

type LoadMoreProps = {
  request: (...args: any[]) => Promise<any>;
  initialItems?: any[];
  pagination: PaginationProps;
  resetTrigger: string;
  onPaginationChange: (pagination: PaginationProps) => void;
};

type State = {
  isLoading: boolean;
  error: ResponseError | null;
  hasNextPage: boolean;
  items: any[];
  pagination: PaginationProps;
};

const initialState: State = {
  isLoading: false,
  error: null,
  hasNextPage: true,
  items: [],
  pagination: { number: 1, size: 20 },
};

type Action = Partial<State> | ((prev: State) => Partial<State>);
type Reducer = (state: State, action: Action) => State;

const reducer: Reducer = (state, action) => {
  if (typeof action === 'function') {
    return {
      ...state,
      ...action(state),
    };
  }

  return {
    ...state,
    ...action,
  };
};

//TODO utilize total count from MetaProps
export function useLoadMore({
  request,
  pagination,
  resetTrigger,
  initialItems = [],
  onPaginationChange,
}: LoadMoreProps) {
  const [
    { isLoading, error, hasNextPage, items, pagination: paginationState },
    dispatch,
  ] = useReducer(reducer, {
    ...initialState,
    items: initialItems,
    hasNextPage:
      initialItems.length >= pagination.size || initialItems.length === 0,
    pagination,
  });

  const reset = useCallback(() => {
    dispatch({
      ...initialState,
      items: [],
      pagination: {
        size: pagination.size,
        number: 1,
      },
    });
  }, [pagination.size]);

  const loadMore = useCallback(async () => {
    dispatch({
      isLoading: true,
      error: null,
    });

    try {
      const data = await request(paginationState);
      dispatch((prev) => ({
        items: [...prev.items, ...data],
        hasNextPage: data.length >= paginationState.size,
        pagination: {
          size: prev.pagination.size,
          number: prev.pagination.number + 1,
        },
      }));
    } catch (errorObj: any) {
      const err = errorObj.errors[0] ?? {
        title: errorObj.error,
        detail: errorObj.exception,
        code: errorObj.status,
      };
      dispatch({
        error: err,
      });
    } finally {
      dispatch({
        isLoading: false,
      });
    }
  }, [paginationState, request]);

  useDidUpdate(() => {
    reset();
  }, [reset, resetTrigger]);

  useDidUpdate(() => {
    onPaginationChange(paginationState);
  }, [onPaginationChange, paginationState.size, paginationState.number]);

  return { isLoading, error, hasNextPage, items, loadMore };
}
