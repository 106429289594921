import { createContext } from 'react';

import { randomId } from '@mantine/hooks';

import { createStore } from 'zustand';

import {
  ConnectedSourceItemType,
  DataSourceOfList,
  ResponseError,
  UserOfList,
} from '@/shared/types';

export type SourcesStoreProps = {
  sourcesList: DataSourceOfList[];
  connectedSources: ConnectedSourceItemType[];
  connectedCreators: Record<string, UserOfList>;
  connectedListHash: string;
  connectedSourcesLoading: boolean;
  requestError: ResponseError | null;
};

export type SourcesStoreState = SourcesStoreProps & {
  setSourcesList: (sourcesList: DataSourceOfList[]) => void;
  setConnectedSources: (connectedSources: ConnectedSourceItemType[]) => void;
  setConnectedCreators: (connectedCreators: Record<string, UserOfList>) => void;
  updateConnectedListHash: () => void;
  setConnectingSourcesLoading: (connectedSourcesLoading: boolean) => void;
};
export type SourcesStore = ReturnType<typeof createSourcesStore>;

export const createSourcesStore = (initProps?: Partial<SourcesStoreProps>) => {
  const DEFAULT_PROPS: Partial<SourcesStoreProps> = {
    sourcesList: [],
    connectedSources: [],
    connectedCreators: {},
    connectedSourcesLoading: true,
    connectedListHash: randomId(),
    requestError: null,
  };
  const initialValues = {
    ...DEFAULT_PROPS,
    ...initProps,
  } as SourcesStoreProps;

  return createStore<SourcesStoreState>()((set) => ({
    ...initialValues,
    setSourcesList: (sourcesList) => set({ sourcesList }),
    setConnectedSources: (connectedSources) => set({ connectedSources }),
    setConnectedCreators: (connectedCreators) => set({ connectedCreators }),
    updateConnectedListHash: () => set({ connectedListHash: randomId() }),
    setConnectingSourcesLoading: (connectedSourcesLoading) =>
      set({ connectedSourcesLoading }),
  }));
};

export const SourcesStoreContext = createContext<SourcesStore | null>(null);
