import { axiosReq } from '@/shared/api';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

type SendInviteResponse = {
  data: {
    id: string;
    type: string;
    attributes: {
      created_at: string;
      updated_at: string;
    };
  };
};
type SendInviteProps = {
  project_id?: string;
  project_role_id?: string;
  user_id?: string;
  company_id: string;
  company_role_id: string;
  email: string;
  full_name?: string;
  token?: string;
  signal?: AbortSignal;
};
export async function sendInvite({
  project_id,
  project_role_id,
  user_id,
  company_id,
  company_role_id,
  email,
  full_name,
  token,
  signal,
}: SendInviteProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/invitations/`;

  return axiosReq<SendInviteResponse>({
    url,
    method: 'POST',
    token,
    signal,
    data: {
      data: {
        type: 'invitations',
        attributes: {
          project_id,
          project_role_id,
          user_id,
          company_id,
          company_role_id,
          email,
          full_name,
        },
      },
    },
  });
}
