import { axiosReq } from '@/shared/api';
import { MetaProps, PaginationProps, RoleOfList } from '@/shared/types';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

type GetAllRolesResponse = {
  data: RoleOfList[];
  meta: MetaProps;
};
type GetAllRolesProps = {
  signal?: AbortSignal;
  token?: string;
};
export async function getAllRoles(
  { signal, token }: GetAllRolesProps,
  pagination: PaginationProps
) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/roles/?page[size]=${pagination.size}&page[number]=${pagination.number}`;

  return axiosReq<GetAllRolesResponse>({
    url,
    method: 'GET',
    token,
    signal,
  });
}
