import { axiosReq } from '@/shared/api';

type ApproveInviteProps = {
  id?: string;
  signal?: AbortSignal;
  token?: string;
};

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

export async function approveInvite({ id, signal, token }: ApproveInviteProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/invitations/${id}/approve`;

  return axiosReq({
    url,
    method: 'GET',
    token,
    signal,
  });
}
