import memoize from 'lodash.memoize';

import { CurrentUser, Nullable } from '@/shared/types';

export enum UserRoles {
  companyEmployee = 'company_employee',
  companyOwner = 'company_owner',
  projectAdmin = 'project_admin',
  projectOwner = 'project_owner',
  guest = 'guest',
}

export function isSuperUser(user: Nullable<CurrentUser>) {
  return user?.attributes.is_superuser ?? false;
}

export function isBackroomUser(user: Nullable<CurrentUser>) {
  return user?.attributes.is_backroom_user ?? false;
}

export const isProjectOwner = memoize(
  (user: Nullable<CurrentUser>, id: Nullable<string>) => {
    if (!user?.attributes.projects_roles) return false;

    return user.attributes.projects_roles.some(
      (role) => role.project_id === id && role.role === 'project_owner'
    );
  },
  (user, id) => `${user?.id}-${id}`
);

export const isProjectAdmin = memoize(
  (user: CurrentUser, id: string) => {
    if (!user.attributes.projects_roles) return false;

    return user.attributes.projects_roles.some(
      (role) => role.project_id === id && role.role === 'project_admin'
    );
  },
  (user, id) => `${user.id}-${id}`
);

export const isCompanyOwner = memoize(
  (user: CurrentUser, id: Nullable<string>) => {
    if (!user.attributes.companies_roles) return false;

    return user.attributes.companies_roles.some(
      (role) => role.company_id === id && role.role === 'company_owner'
    );
  },
  (user, id) => `${user.id}-${id}`
);

export const isCompanyEmployee = memoize(
  (user: CurrentUser, id: string) => {
    if (!user.attributes.companies_roles) return false;

    return user.attributes.companies_roles.some(
      (role) => role.company_id === id && role.role === 'company_employee'
    );
  },
  (user, id) => `${user.id}-${id}`
);

export const canDeleteProject = (
  currentUser: Nullable<CurrentUser>,
  projectId: Nullable<string>,
  projectCompanyId: Nullable<string>
) => {
  if (!currentUser) return false;

  return (
    isSuperUser(currentUser) ||
    isProjectOwner(currentUser, projectId) ||
    isCompanyOwner(currentUser, projectCompanyId)
  );
};

export function canViewInvitesInProject(
  currentUser: Nullable<CurrentUser>,
  projectId: Nullable<string>,
  projectCompanyId: Nullable<string>
) {
  if (!currentUser) return false;

  return (
    isSuperUser(currentUser) ||
    isProjectOwner(currentUser, projectId) ||
    isCompanyOwner(currentUser, projectCompanyId)
  );
}

export function canAddOnlyGuestInProject(
  currentUser: Nullable<CurrentUser>,
  projectId: Nullable<string>,
  projectCompanyId: Nullable<string>
) {
  if (!currentUser) return false;

  return (
    !isSuperUser(currentUser) &&
    !isProjectOwner(currentUser, projectId) &&
    !isCompanyOwner(currentUser, projectCompanyId)
  );
}

export function canAddAndRemoveAUserInProject(
  currentUser: Nullable<CurrentUser>,
  projectId: Nullable<string>,
  projectCompanyId: Nullable<string>
) {
  if (!currentUser) return false;

  return (
    isSuperUser(currentUser) ||
    isProjectOwner(currentUser, projectId) ||
    isCompanyOwner(currentUser, projectCompanyId)
  );
}

export function canApproveOrRejectInvitationToProject(
  currentUser: Nullable<CurrentUser>,
  companyId: Nullable<string>
) {
  if (!currentUser) return false;

  const isSU = isSuperUser(currentUser);

  if (!companyId) return isSU;

  return isSU || isCompanyOwner(currentUser, companyId);
}

export function canViewAllProjectsOfAllCompanies(currentUser: CurrentUser) {
  return isSuperUser(currentUser);
}

export function canViewAllProjectsOfCompany(
  currentUser: CurrentUser,
  companyId: string
) {
  return isSuperUser(currentUser) || isCompanyOwner(currentUser, companyId);
}

export const canViewUserManagementPage = memoize(
  (currentUser: Nullable<CurrentUser>) => {
    if (!currentUser) return false;

    const isSU = isSuperUser(currentUser);

    return (
      isSU ||
      currentUser.attributes.companies_roles.some((role) => {
        return role.role === UserRoles.companyOwner;
      })
    );
  },
  (currentUser) => currentUser?.id
);

export const canViewDashboardsManagementPage = memoize(
  (currentUser: Nullable<CurrentUser>) => {
    if (!currentUser) return false;

    return isBackroomUser(currentUser);
  },
  (currentUser) => currentUser?.id
);

export function canViewAllUsersOfAllCompanies(currentUser: CurrentUser) {
  return isSuperUser(currentUser);
}

export function canViewAllUsersOfCompany(
  currentUser: CurrentUser,
  companyId: string
) {
  return isSuperUser(currentUser) || isCompanyOwner(currentUser, companyId);
}
export function canApproveOrRejectUserOfCompany(
  currentUser: CurrentUser,
  companyId: string
) {
  return isSuperUser(currentUser) || isCompanyOwner(currentUser, companyId);
}
export function canAddOrRemoveUserOfCompany(
  currentUser: CurrentUser,
  companyId: Nullable<string>
) {
  return isSuperUser(currentUser) || isCompanyOwner(currentUser, companyId);
}

export function canCreateOrRemoveCompany(currentUser: Nullable<CurrentUser>) {
  return isSuperUser(currentUser);
}

export function canPromoteUserToCompanyOwner(currentUser: CurrentUser) {
  return isSuperUser(currentUser);
}
