import { CompanyOfList, CurrentUser } from '@/shared/types';
import { UserRoles } from '@/shared/userRoles';

export function getOwnCompanies(
  companies: CompanyOfList[],
  companiesRoles: CurrentUser['attributes']['companies_roles']
) {
  const ownCompaniesIds = companiesRoles
    .filter((role) => role.role === UserRoles.companyOwner)
    .map((role) => role.company_id);

  return companies.filter((company) => {
    return ownCompaniesIds.includes(company.id);
  });
}
