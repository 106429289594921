'use client';

import { redirect } from 'next/navigation';

import { PropsWithChildren, useEffect, useRef } from 'react';

import { captureException } from '@sentry/core';

import { logoutCurrentUser } from '@/entities/user/api';

import { PAGES } from '@/shared/constants';

import {
  createCurrentUserStore,
  CurrentUserContext,
  CurrentUserProps,
  CurrentUserStore,
} from './currentUserContext';

export const CurrentUserProfileProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<CurrentUserProps>>) => {
  const storeRef = useRef<CurrentUserStore>();

  if (!storeRef.current) {
    storeRef.current = createCurrentUserStore(props);
  }

  useEffect(() => {
    if (!props.user) {
      redirect(`${PAGES.LOGIN}/?error=unauthorized`);
    }

    if (!props.user.attributes.is_approved) {
      redirect(`${PAGES.AWAITING_APPROVAL}/`);
    }

    if (props.user.attributes.deleted_at) {
      logoutCurrentUser().catch((error) => {
        captureException(error);
      });
      redirect(`${PAGES.LOGIN}/`);
    }

    window.currentUserId = props.user.id;
  }, [props.user]);

  return (
    <CurrentUserContext.Provider value={storeRef.current}>
      {children}
    </CurrentUserContext.Provider>
  );
};
