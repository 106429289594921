import { PropsWithChildren, useRef } from 'react';

import {
  createSourcesStore,
  SourcesStore,
  SourcesStoreContext,
  SourcesStoreProps,
} from '.';

export const SourcesStoreProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<SourcesStoreProps>>) => {
  const storeRef = useRef<SourcesStore>();

  if (!storeRef.current) {
    storeRef.current = createSourcesStore(props);
  }

  return (
    <SourcesStoreContext.Provider value={storeRef.current}>
      {children}
    </SourcesStoreContext.Provider>
  );
};
