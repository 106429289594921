import { createContext } from 'react';

import { RoleOfList } from '@/shared/types';

export type AllRolesContextState = {
  roles: RoleOfList[];
};

export const AllRolesContext = createContext<AllRolesContextState>({
  roles: [],
});
