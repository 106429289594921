import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ConnectYoutubeType = {
  projectId: string;
  step: number;
};

export type ConnectYoutubeStoreState = {
  connectYoutubeModals: ConnectYoutubeType[];
  addItem: (item: ConnectYoutubeType) => void;
  removeItem: (projectId: string) => void;
  updateItem: (item: ConnectYoutubeType) => void;
};

export const useConnectYoutubeStore = create<ConnectYoutubeStoreState>()(
  persist(
    (set, get) => ({
      connectYoutubeModals: [],
      addItem: (item: ConnectYoutubeType) => {
        const { connectYoutubeModals } = get();
        set({ connectYoutubeModals: [...connectYoutubeModals, item] });
      },
      removeItem: (projectId: string) => {
        const { connectYoutubeModals } = get();
        set({
          connectYoutubeModals: connectYoutubeModals.filter(
            (item) => item.projectId !== projectId
          ),
        });
      },
      updateItem: (item: ConnectYoutubeType) => {
        const { connectYoutubeModals } = get();
        const resultArray = connectYoutubeModals.map((modal) =>
          modal.projectId === item.projectId ? item : modal
        );
        set({
          connectYoutubeModals: resultArray,
        });
      },
    }),
    { name: 'connect-youtube-storage' }
  )
);
