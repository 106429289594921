import { axiosReq } from '@/shared/api';

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL || '';

export type SetUserCompaniesResponse = {};
type SetUserCompaniesProps = {
  id: string;
  signal?: AbortSignal;
  companiesIds: string[];
  roleIds: string[];
  inviterId: string;
};
export async function setUserCompanies({
  id,
  companiesIds,
  inviterId,
  roleIds,
  signal,
}: SetUserCompaniesProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/users/${id}/set_companies/`;

  return axiosReq<SetUserCompaniesResponse>({
    url,
    method: 'POST',
    signal,
    data: {
      data: {
        type: 'companies_list',
        attributes: {
          list: companiesIds.join(', '),
          roles_list: roleIds.join(', '),
          inviter_id: inviterId,
        },
      },
    },
  });
}
