import { createContext } from 'react';

import { randomId } from '@mantine/hooks';

import { createStore } from 'zustand';

import { CompanyOfList } from '@/shared/types';

export type CompaniesProps = {
  companiesList: CompanyOfList[];
  listHash: string;
  selectedCompany: string;
};
export type CompaniesState = CompaniesProps & {
  updateListHash: () => void;
  setCompaniesList: (list: CompanyOfList[]) => void;
  setSelectedCompany: (id: string) => void;
};

export type CompaniesStore = ReturnType<typeof createCompaniesStore>;

export const createCompaniesStore = (initProps?: Partial<CompaniesProps>) => {
  const DEFAULT_PROPS: Partial<CompaniesProps> = {
    listHash: randomId(),
  };
  const initialValues = {
    ...DEFAULT_PROPS,
    ...initProps,
  } as CompaniesProps;

  return createStore<CompaniesState>()((set) => ({
    ...initialValues,
    updateListHash: () => set({ listHash: randomId() }),
    setCompaniesList: (companiesList) => set({ companiesList }),
    setSelectedCompany: (selectedCompany) => set({ selectedCompany }),
  }));
};

export const CompaniesStoreContext = createContext<CompaniesStore | null>(null);
