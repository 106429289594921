import { ActionIcon } from '@mantine/core';
import { IconSelector } from '@tabler/icons-react';

import styles from './SortableTableHeaderItem.module.css';

type SortableTableHeaderItemProps = {
  onClick: (sortField: string) => void;
  label: string;
  value: string;
  dataTestId?: string;
};
export const SortableTableHeaderItem = ({
  onClick,
  label,
  value,
  dataTestId,
}: SortableTableHeaderItemProps) => {
  return (
    <ActionIcon
      c="dark.9"
      className={styles.root}
      data-testid={dataTestId}
      variant="transparent"
      onClick={() => onClick(value)}
    >
      {label}

      <IconSelector className={styles.icon} />
    </ActionIcon>
  );
};
