import { Button, Container, Group, Text, Title } from '@mantine/core';

import styles from './ErrorPage.module.css';

export function ErrorPage() {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.label}>500</div>

        <Title className={styles.title}>Something bad just happened...</Title>

        <Text
          className={styles.description}
          component="div"
          size="lg"
          ta="center"
        >
          Our servers could not handle your request. Don&apos;t worry, our
          development team was already notified. Try refreshing the page.
        </Text>

        <Group justify="center">
          <Button size="md" variant="white" onClick={handleRefresh}>
            Refresh the page
          </Button>
        </Group>
      </Container>
    </div>
  );
}
