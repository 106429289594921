import { allTimezones, useTimezoneSelect } from 'react-timezone-select';

import { Select, SelectProps } from '@mantine/core';

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
};

export const TimezoneSelect = ({ onChange, ...props }: SelectProps) => {
  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  return <Select data={options} placeholder="Select timezone" {...props} />;
};
