import { axiosReq } from '@/shared/api';

type RejectInviteProps = {
  id?: string;
  signal?: AbortSignal;
  token?: string;
};

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

export async function rejectInvite({ id, signal, token }: RejectInviteProps) {
  const url = `${NEXT_PUBLIC_BACKEND_URL}v1/invitations/${id}/reject`;

  return axiosReq({
    url,
    method: 'GET',
    token,
    signal,
  });
}
