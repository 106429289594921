import { axiosReq } from '@/shared/api';

type AcceptSignDocumentsProps = {
  documentsIds: string[];
  signal?: AbortSignal;
  token?: string;
};

const NEXT_PUBLIC_BACKEND_URL = process.env.NEXT_PUBLIC_BACKEND_URL;

export async function AcceptSignDocuments({
  documentsIds,
  signal,
  token,
}: AcceptSignDocumentsProps) {
  let url = `${NEXT_PUBLIC_BACKEND_URL}v1/documents_versions/sign/?docs=${documentsIds.join(
    ','
  )}`;
  return axiosReq({
    url,
    method: 'GET',
    token,
    signal,
  });
}
