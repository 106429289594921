import { createContext } from 'react';

import { randomId } from '@mantine/hooks';

import { createStore } from 'zustand';

import {
  DashboardOfList,
  SharedDashboardOfList,
  UserOfList,
} from '@/shared/types';

export type DashboardsStoreProps = {
  dashboardsList: DashboardOfList[];
  dashboardsListHash: string;
  dashboardsLoading: boolean;
  sharedDashboardsList: SharedDashboardOfList[];
  sharedDashboardsListHash: string;
  sharedDashboardsLoading: boolean;
  sharedDashboardsCreators: UserOfList[];
};

export type DashboardsStoreState = DashboardsStoreProps & {
  setDashboardsList: (dashboardsList: DashboardOfList[]) => void;
  updateDashboardsListHash: () => void;
  setDashboardsLoading: (loading: boolean) => void;
  setSharedDashboardsList: (
    sharedDashboardsList: SharedDashboardOfList[]
  ) => void;
  updateSharedDashboardsListHash: () => void;
  setSharedDashboardsLoading: (loading: boolean) => void;
  setSharedDashboardsCreators: (creators: UserOfList[]) => void;
};
export type DashboardsStore = ReturnType<typeof createDashboardsStore>;

export const createDashboardsStore = (
  initProps?: Partial<DashboardsStoreProps>
) => {
  const DEFAULT_PROPS: Partial<DashboardsStoreProps> = {
    dashboardsList: [],
    dashboardsLoading: true,
    dashboardsListHash: randomId(),
    sharedDashboardsList: [],
    sharedDashboardsLoading: true,
    sharedDashboardsCreators: [],
    sharedDashboardsListHash: randomId(),
  };
  const initialValues = {
    ...DEFAULT_PROPS,
    ...initProps,
  } as DashboardsStoreProps;

  return createStore<DashboardsStoreState>()((set) => ({
    ...initialValues,
    setDashboardsList: (dashboardsList) => set({ dashboardsList }),
    updateDashboardsListHash: () => set({ dashboardsListHash: randomId() }),
    setDashboardsLoading: (dashboardsLoading) => set({ dashboardsLoading }),
    setSharedDashboardsList: (sharedDashboardsList) =>
      set({ sharedDashboardsList }),
    updateSharedDashboardsListHash: () =>
      set({ sharedDashboardsListHash: randomId() }),
    setSharedDashboardsLoading: (sharedDashboardsLoading) =>
      set({ sharedDashboardsLoading }),
    setSharedDashboardsCreators: (sharedDashboardsCreators) =>
      set({ sharedDashboardsCreators }),
  }));
};
export const DashboardsStoreContext = createContext<DashboardsStore | null>(
  null
);
