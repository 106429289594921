'use client';

import { useMemo } from 'react';

import { useProjectStore } from '@/entities/project/store';

import { UserOfList } from '@/shared/types';
import { UserRoles } from '@/shared/userRoles';

type UserRoleInProjectProps = {
  roles: UserOfList['attributes']['projects_roles'];
};
export function UserRoleInProject({ roles }: UserRoleInProjectProps) {
  const { projectId } = useProjectStore('projectId');

  const projectRole = useMemo(() => {
    if (!projectId || !roles) {
      return 'Unknown role';
    }

    const foundRole = roles.find((role) => role.project_id === projectId)?.role;
    const result =
      foundRole === UserRoles.projectOwner
        ? 'Owner'
        : foundRole === UserRoles.guest
        ? 'Guest'
        : 'User';

    return result;
  }, [projectId, roles]);

  return <span>{projectRole}</span>;
}
