import { MouseEventHandler } from 'react';

import { ActionIcon, Flex } from '@mantine/core';
import { IconLayoutGrid, IconLayoutList } from '@tabler/icons-react';

import { useQueryState } from 'nuqs';

import { useCompaniesStore } from '@/entities/companies/client';
import { useCurrentUserStore } from '@/entities/user/client';

import { sendEvent } from '@/shared/analytics';

import styles from './LayoutSwitch.module.css';

type Variant = 'grid' | 'list';
type LayoutSwitchProps = {
  onChange: (value: Variant) => void;
  value: Variant;
};
export const LayoutSwitch = ({
  onChange,
  value = 'grid',
}: LayoutSwitchProps) => {
  const { selectedCompany, companiesList } = useCompaniesStore(
    'selectedCompany',
    'companiesList'
  );
  const { user } = useCurrentUserStore('user');
  const [, setSearchParamsViewMode] = useQueryState('viewMode');

  const handleChange: MouseEventHandler<HTMLButtonElement> = (event) => {
    const selected = event.currentTarget.dataset.value as Variant;
    setSearchParamsViewMode(selected).then((searchParams) =>
      onChange(searchParams.get('viewMode') as Variant)
    );

    const company =
      companiesList.find((c) => c.id === selectedCompany)?.attributes.title ??
      'all';
    sendEvent({
      event_name: 'display_format',
      company: company,
      type: selected,
    });
  };

  return (
    <Flex className={styles.root} gap={5}>
      <ActionIcon
        color={value === 'grid' ? 'var(--mantine-color-anchor)' : 'gray'}
        data-testid="grid"
        data-value="grid"
        radius="md"
        variant={value === 'grid' ? 'outline' : 'transparent'}
        onClick={handleChange}
      >
        <IconLayoutGrid />
      </ActionIcon>

      <ActionIcon
        color={value === 'list' ? 'var(--mantine-color-anchor)' : 'gray'}
        data-testid="list"
        data-value="list"
        radius="md"
        variant={value === 'list' ? 'outline' : 'transparent'}
        onClick={handleChange}
      >
        <IconLayoutList />
      </ActionIcon>
    </Flex>
  );
};
